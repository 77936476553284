import React, { useRef, useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Cookies from 'js-cookie';
import qs from 'query-string';
import { CurrencyProvider } from 'day8-react/build/currency';
import { isDev } from 'day8-react/build/content';
import { MediaContextProvider } from 'day8-react/build/new-components';
import '../styles/styles.scss';
import { LuckyModalProvider } from '../context/LuckyModalContext';
import LuckyModal from '../components/lucky-week/LuckyModal';
import { Toaster } from 'day8-ui';

interface CustomAppProps extends AppProps {
  pageProps: {
    dehydratedState: {}
  }
}
function MyApp({ Component, pageProps }: CustomAppProps) {
  useEffect(() => {
    // replace with next router useRouters
    const { tywRef }: { tywRef?: string } = qs.parse(window.location.search);
    if (tywRef) {
      Cookies.set('tyw_affiliate_code', tywRef, { expires: 90 });
    }
  }, []);
  const AnyComponent = Component as any;
  return (
    <CurrencyProvider>
      <MediaContextProvider disableDynamicMediaQueries={isDev}>
        <LuckyModalProvider>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Head>
          <AnyComponent {...pageProps} />
          <LuckyModal />
          <Toaster position="top-right" style={{ marginTop: '64px' }} />
        </LuckyModalProvider>
      </MediaContextProvider>
    </CurrencyProvider>
  );
}

export default MyApp;