import { useForm } from 'day8-react';
import qs from 'query-string';
import { useLuckyModal } from "../../context/LuckyModalContext";
import { useState, useEffect } from "react";
import Calendar from "./steps/Calendar";
import Crew from "./steps/Crew";
import ModalHead from "./components/ModalHead";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button, Card, Dialog, toast } from 'day8-ui';
import { RxCross1 } from "react-icons/rx";
import Countries from './steps/Countries';

export type LuckyModalFormData = {
  month: number[];
  hostess: number;
  capacity: number;
  yearFilter: number;
  destinationFilter: number;
  countryFilter: string;
  cabinBookingType: boolean;
  customYachts: boolean;
}
export type ModalHeadProps = {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
}

const LuckyModal = () => {
  const [modalHeadProps, setModalHeadProps] = useState<ModalHeadProps | null>(null);
  const [openItem, setOpenItem] = useState<string | undefined>();
  const { formData, handleChange, resetForm } = useForm({
    month: [],
    capacity: 8,
    hostess: 0,
    countryFilter: null,
    cabinBookingType: null,
    customYachts: null,
    yearFilter: null
  });
  const { destinations, countries, isModalOpen, toggle, hasFilterCookie, clearFilterCookie, filterCookie, defaultSection, setDefaultSection } = useLuckyModal();
  const getMonthName = (months: number[]) => {

    return months.map(month => {
      const date = new Date();
      date.setMonth(month);
      return date.toLocaleString('default', { month: 'long' });
    }).join(', ') // months are 0-indexed in JavaScript
  }

  const buildQueryParams = () => {
    const girls = Math.round(formData.capacity / 2);
    const boys = formData.capacity - girls;
    const query = {
      ...formData,
      girls,
      boys
    };
    delete query['capacity'];
    delete query['customYachts'];
    if (boys + girls < 4) {
      query.cabinBookingType = true;
    }
    const url = qs.stringifyUrl({
      url: formData.customYachts ? '/browse/yachts' : '/browse',
      query: query
    }, { skipNull: true });
    if (hasFilterCookie) {
      clearFilterCookie();
    }
    window.location.href = url;
  };

  useEffect(() => {
    // if for any reason nothing came here, redirect to funnel
    if (isModalOpen && !destinations?.length) {
      window.location.href = '/browse';
    }
    // Disabled for now, Might need to bring back
    // if (hasFilterCookie && isModalOpen) {
    //   const filterCookieJson = JSON.parse(filterCookie);
    //   const { destinationFilter } = filterCookieJson;

    //   let defaultMessage = "Continue your previous search?";
    //   if (Array.isArray(destinationFilter) && destinationFilter.length > 0) {
    //     const destNameArray = destinations && destinations.filter(d => destinationFilter.includes(d.id.toString())).map(d => d.name);
    //     if (destNameArray.length > 0) defaultMessage = `You were previously searching for ${destNameArray.join(', ')}. Continue your search?`
    //   }

    //   toast.custom((t) =>
    //     <div className='dui-flex dui-flex-col dui-max-w-[350px] dui-w-full dui-bg-white dui-border dui-border-primary-950 dui-border-solid dui-p-4'>
    //       <p className='dui-mb-4'>{defaultMessage}</p>
    //       <div className='dui-flex dui-justify-between dui-max-w-full'>
    //         <Button buttonType='SECONDARY' className="dui-w-[250px] dui-max-w-full" onClick={() => toast.dismiss(t)}>Close</Button>
    //         <Button className="dui-w-[250px] dui-max-w-full" onClick={() => window.location.href = '/browse'}>Continue</Button>
    //       </div>
    //     </div>
    //     , {
    //       id: 'filter',
    //       duration: 50000,
    //     });
    // }
  }, [isModalOpen]);

  const handleToggle = () => {
    // toast.dismiss("filter");
    toggle();
  }

  return (
    <Dialog
      className='!dui-p-0'
      open={isModalOpen}
      onOpenChange={handleToggle}
      scrollable
      showFooter
      dialogType='FULLSCREEN_MOBILE'
      onPointerDownOutside={(e: CustomEvent<{ originalEvent: PointerEvent }>) => e.preventDefault()}
      footerComponent={(
        <div className='dui-sticky dui-bottom-0 dui-mb-[75px] md:dui-mb-0 dui-z-10 dui-bg-white dui-p-4 dui-flex dui-mt-4 dui-gap-4'>
          <Button className="dui-w-full" buttonType='SECONDARY' onClick={() => resetForm()}>{'Clear all'}</Button>
          <Button className="dui-w-full" onClick={buildQueryParams}>Search</Button>
        </div>
      )}>
      <div className='dui-px-4 d8-c-lucky-modal dui-min-h-[calc(100vh-96px)] lg:dui-min-h-0'>
        <div className='dui-flex dui-bg-white dui-z-20 dui-pt-4 dui-pb-4 dui-justify-end dui-sticky dui-top-0'><Button buttonType='LINK' onClick={handleToggle}><RxCross1 /></Button></div>
        <Accordion defaultValue={defaultSection} value={openItem && openItem} onValueChange={setOpenItem}>
          <AccordionItem value='where' className='dui-mb-4'>
            <AccordionTrigger className='aria-[expanded=true]:dui-hidden dui-w-full'>
              <Card className='dui-w-full dui-text-left dui-border dui-font-subheading dui-font-bold dui-flex dui-justify-between' cardType='BORDERED'><span>Where</span><span className='dui-text-secondary-500'>{formData.countryFilter ?? "I'm flexible"}</span></Card>
            </AccordionTrigger>
            <AccordionContent className='dui-border dui-border-solid dui-border-primary-950 dui-p-4'>
              {modalHeadProps && <ModalHead {...modalHeadProps} />}
              <Countries onNext={() => setOpenItem('when')} formData={formData} handleChange={handleChange} setModalHeadProps={setModalHeadProps} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value='when' className='dui-mb-4'>
            <AccordionTrigger className='aria-[expanded=true]:dui-hidden dui-w-full'>
              <Card className='dui-w-full dui-text-left dui-border dui-font-subheading dui-font-bold dui-flex dui-justify-between' cardType='BORDERED'><span>When</span><span className='dui-text-secondary-500'>{formData.month?.length > 0 ? getMonthName(formData.month) : "I'm flexible"}</span></Card>
            </AccordionTrigger>
            <AccordionContent className='dui-border dui-border-solid dui-border-primary-950 dui-p-4'>
              {modalHeadProps && <ModalHead {...modalHeadProps} />}
              <Calendar onNext={() => setOpenItem('who')} formData={formData} handleChange={handleChange} setModalHeadProps={setModalHeadProps} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value='who' className='dui-mb-4'>
            <AccordionTrigger className='aria-[expanded=true]:dui-hidden dui-w-full'>
              <Card className='dui-w-full dui-text-left dui-border dui-font-subheading dui-font-bold dui-flex dui-justify-between' cardType='BORDERED'><span>Who</span><span className='dui-text-secondary-500'>{formData.capacity ? formData.capacity + " people" : "I'm flexible"}</span></Card>
            </AccordionTrigger>
            <AccordionContent className='dui-border dui-border-solid dui-border-primary-950 dui-p-4'>
              {modalHeadProps && <ModalHead {...modalHeadProps} />}
              <Crew formData={formData} handleChange={handleChange} setModalHeadProps={setModalHeadProps} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </Dialog>
  )
};

export default LuckyModal;