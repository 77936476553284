import { Button } from 'day8-ui';
import { Fragment } from 'react';
import { CiSearch } from "react-icons/ci";


type ModalHeadProps = {
  icon?: React.ReactNode,
  title: string,
  subtitle?: string
}
const ModalHead: React.FC<ModalHeadProps> = ({ icon, title, subtitle }) => {
  return (
    <Fragment>
      <div className="dui-pb-8 dui-bg-white">
        <div className='dui-flex dui-justify-between dui-items-center'>
          <div className='dui-text-2xl dui-flex dui-items-center dui-gap-4'>
            {icon ?? <CiSearch />}
            <h2 className=''>{title}</h2>
          </div>
        </div>
        {subtitle && <p className='dui-mt-4'>{subtitle}</p>}
      </div>
      {/* <hr className="margin-y-md" style={{ height: '1px', background: 'var(--color-contrast-low)' }} /> */}
    </Fragment>
  );
};

export default ModalHead;